import React, { Component } from 'react';

import axios from 'axios';
import { getRoot, mapboxToken } from '../utils';
import PageNotReady from '../pages/PageNotReady';

import mapboxgl from 'mapbox-gl';
import styleJson from '../styling/mapbox/bike-trip.json';
import { homeIcon } from '../utils';

mapboxgl.accessToken = mapboxToken;

class WorldMap extends Component {

  constructor(props) {
    super(props);

    this.state = {
      waypoints: [],
      isLoading: true,
      error: null
    };

    this.loadMap = this.loadMap.bind(this);
    this.source = axios.CancelToken.source();
  }

  componentDidMount() {

    const rootUrl = getRoot();

    this.axiosCancelSource = axios.CancelToken.source();
    axios.get(rootUrl + '/api/?request=waypoints', { cancelToken: this.source.token })
      .then(result => {
        this.setState({
          waypoints: result.data,
          isLoading: false
        });
        this.loadMap();
      })
      .catch(error => this.setState({
        error,
        isLoading: false
      }));
  }

  componentWillUnmount() {
    this.source.cancel();

    // Make sure to destroy map instance on unmounting to prevent memory leaks
    if(this.map) {
      this.map.destroy();
    }
  }

  loadMap () {

    // global constants for the map
    const pitch = 0;
    const zoom = 2;
    const homeLocation = [4.83137, 52.35396];
    const mapCenter = [5, 25]
    const { waypoints } = this.state;
    const routeLayer = 'road-number-shield';
    const icon = homeIcon;

    // the different line styles
    const bike = {
      'line-color': '#BD0101',
      'line-width': 2
    };

  	const automobile = {
  		'line-color': '#74723a',
  		'line-dasharray': [6,3],
  		'line-opacity': 0.7,
  		'line-width': 1
  	};

  	const aeroplane = {
  		'line-color': '#74723a',
  		'line-width': 1,
      'line-opacity': 0.4
  	};

  	const boat = {
  		'line-color': '#00F',
  		'line-dasharray': [6,3],
  		'line-width': 1.5,
  	};

    // this gets a little repetitive
    const createPolyline = (id, route, lineType) => {
      return {
        'id': id,
        'type': 'line',
        'source': {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'geometry': {
              'type': 'LineString',
              'coordinates': route
            }
          }
        },
        'paint': lineType
      }
    }

    // import image assets and json styling file
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: styleJson,
      pitch: pitch,
      zoom: zoom,
      center: mapCenter,
    });
    map.on('load', () => {

      const image = new Image();
      image.src = icon;

      image.onload = () => {
        map.addImage('home', image);
        map.addLayer({
            "id": "points",
            "type": "symbol",
            "source": {
              "type": "geojson",
              "data": {
                "type": "FeatureCollection",
                "features": [{
                  "type": "Feature",
                  "geometry": {
                    "type": "Point",
                    "coordinates": homeLocation
                  }
                }]
              }
            },
            "layout": {
              "icon-image": "home",
              "icon-size": 0.6
            }
          });
        };

      // eurasia
      map.addLayer(createPolyline('eur-1', waypoints.eurasia.westernEurope, bike), routeLayer);
      map.addLayer(createPolyline('eur-2', waypoints.eurasia.adriatic, boat), routeLayer);
      map.addLayer(createPolyline('eur-3', waypoints.eurasia.easternEurope, bike), routeLayer);
      map.addLayer(createPolyline('eur-4', waypoints.eurasia.bosphorus, boat), routeLayer);
      map.addLayer(createPolyline('eur-5', waypoints.eurasia.turkeyCaucasus, bike), routeLayer);
      map.addLayer(createPolyline('eur-6', waypoints.eurasia.caspian, boat), routeLayer);
      map.addLayer(createPolyline('eur-7', waypoints.eurasia.kyzlykum, bike), routeLayer);
      map.addLayer(createPolyline('eur-8', waypoints.eurasia.pamirHighway, bike), routeLayer);
      map.addLayer(createPolyline('eur-9', waypoints.eurasia.westXinjiang, bike), routeLayer);
      map.addLayer(createPolyline('eur-10', waypoints.eurasia.policeEscort, automobile), routeLayer);
      map.addLayer(createPolyline('eur-11', waypoints.eurasia.eastXinjiang, bike), routeLayer);
      map.addLayer(createPolyline('eur-12', waypoints.eurasia.china, bike), routeLayer);
      map.addLayer(createPolyline('eur-13', waypoints.eurasia.airSingapore, aeroplane), routeLayer);

      // down under
      map.addLayer(createPolyline('aus-1', waypoints.downunder.australia, bike), routeLayer);
      map.addLayer(createPolyline('aus-2', waypoints.downunder.tasmanSea, aeroplane), routeLayer);
      map.addLayer(createPolyline('aus-3', waypoints.downunder.southIslandOne, bike), routeLayer);
      map.addLayer(createPolyline('aus-4', waypoints.downunder.lakeWakatipu, boat), routeLayer);
      map.addLayer(createPolyline('aus-5', waypoints.downunder.southIslandTwo, bike), routeLayer);
      map.addLayer(createPolyline('aus-6', waypoints.downunder.cookStrait, boat), routeLayer);
      map.addLayer(createPolyline('aus-7', waypoints.downunder.northIsland, bike), routeLayer);
      map.addLayer(createPolyline('aus-8', waypoints.downunder.westernPacific, aeroplane), routeLayer);
      map.addLayer(createPolyline('aus-9', waypoints.downunder.easternPacific, aeroplane), routeLayer);

      // america
      map.addLayer(createPolyline('ame-1', waypoints.america.westernUS, bike), routeLayer);
      map.addLayer(createPolyline('ame-2', waypoints.america.easternUS, bike), routeLayer);
      map.addLayer(createPolyline('ame-3', waypoints.america.atlantic, aeroplane), routeLayer);

      // back home via the camino
      map.addLayer(createPolyline('eur-14', waypoints.eurasia.camino, bike), routeLayer);

      map.addSource('dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.terrain-rgb'
      }).addLayer({
        'id': 'hillshading',
        'source': 'dem',
        'type': 'hillshade',
        'paint': {
          'hillshade-exaggeration': 0.8,
          'hillshade-shadow-color': '#362900'
        }
      }, 'contour-line');
    });

    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'bottom-right').scrollZoom.disable();
  }

  render() {
    const { isLoading, error } = this.state;

    if(isLoading) { return <div className="map"><PageNotReady pageType="loading" isComponent /></div> }
    if(error)     { return <div className="map"><PageNotReady pageType="error" errorMessage={error.message} isComponent /></div> }

    return (
      <div className="map">
        <div className="map__container" ref={el => this.mapContainer = el} />
      </div>
    );
  }
}

export default WorldMap;
