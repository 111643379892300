import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Polaroid from './Polaroid';
import PageNotFound from '../pages/PageNotFound';

import { createMarkup } from '../utils';
import classnames from 'classnames';

import 'picturefill';
import 'picturefill/dist/plugins/mutation/pf.mutation.min';

class Case extends Component {

  constructor(){
    super();

    this.state = {
      prev: false,
      next: false,
      esc: false,
      showCaption: true
    };

    this.toggleCaption = this.toggleCaption.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    document.addEventListener('keydown', this.handleKeyUp);
  }

  componentDidUpdate() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyUp);

    const nextPage = this.props.history.location.pathname;
    const isPhotograph = this.props.isPhotograph;

    if((nextPage === '/portfolio' && !isPhotograph) || (nextPage === '/bike-trip/pictures' && isPhotograph)) {

      const item = this.props.data.find(item => item.url === this.props.match.params.name);
      if(item) {
        this.props.updateScrollItem(item.url);
      }
    }
  }

  toggleCaption() {
    const captionStatus = this.state.showCaption;
		this.setState({ showCaption: !captionStatus });
  }

  handleKeyUp = (event) => {
    if(!this.prevBtn || !this.nextBtn) {
      return;
    }

    const prevPage = this.prevBtn.props.page;
    const nextPage = this.nextBtn.props.page;

    switch(event.key) {
      case 'ArrowLeft':
        if(prevPage) { this.props.history.push(prevPage); }
        break;
      case 'ArrowRight':
        if(nextPage) { this.props.history.push(nextPage); }
        break;
      case 'Escape':
        if(this.props.isPhotograph) {
          this.props.history.push('/bike-trip/pictures');
        }
        else {
          this.props.history.push('/portfolio');
        }
        break;
      default:
        break;
    }
  }

  render() {

    const data = this.props.data;
    const isPhotograph = this.props.isPhotograph;
    const url = this.props.match.params.name;
    const { showCaption } = this.state;

    // get the total number of items and current position in the array
    const numPieces = data.length;
    const item = data.find(item => item.url === url);
    const curIndex = data.findIndex(curIndex => curIndex.url === url );

    // check whether a next and/or previous item exist in the array
    const nextExists = curIndex < (numPieces - 1);
    const prevExists = curIndex > 0;

    // faulty url, present a 404 warning
    if(!item) {
      return <PageNotFound data={data} isPhotograph={isPhotograph} />;
    }

    //  -------------------------------------- bike trip --------------------------------------
    if(isPhotograph) {
      let country = null;
      let flag = null;

      switch(item.country) {
  			case 'AU':
  				country = 'Australia';
  				flag = 'flag--au';
  				break;
  			case 'AZ':
  				country = 'Azerbaijan';
  				flag = 'flag--az';
  				break;
  			case 'BE':
  				country = 'Belgium';
  				flag = 'flag--be';
  				break;
  			case 'CN':
  				country = 'China';
  				flag = 'flag--cn';
  				break;
  			case 'FR':
  				country = 'France';
  				flag = 'flag--fr';
  				break;
  			case 'ES':
  				country = 'Spain';
  				flag = 'flag--es';
  				break;
  			case 'GE':
  				country = 'Georgia';
  				flag = 'flag--ge';
  				break;
  			case 'KG':
  				country = 'Kyrgyzstan';
  				flag = 'flag--kg';
  				break;
  			case 'KZ':
  				country = 'Kazakhstan';
  				flag = 'flag--kz';
  				break;
  			case 'NL':
  				country = 'Netherlands';
  				flag = 'flag--nl';
  				break;
  			case 'NZ':
  				country = 'New Zealand';
  				flag = 'flag--nz';
  				break;
  			case 'PT':
  				country = 'Portugal';
  				flag = 'flag--pt';
  				break;
  			case 'TJ':
  				country = 'Tajikistan';
  				flag = 'flag--tj';
  				break;
  			case 'US':
  				country = 'United States';
  				flag = 'flag--us';
  				break;
  			case 'UZ':
  				country = 'Uzbekistan';
  				flag = 'flag--uz';
  				break;
  			default:
  				country = '';
  				flag = '';
  				break;
  		}

      return(
        <div className={classnames({'photograph': true, 'photograph--hide-caption': showCaption === false })}>
          <Helmet>
            <title>{`Bike Trip - ${item.title}`}</title>
          </Helmet>
            <div className="photograph__visual">
              <picture className="photograph__picture">
                <source srcSet={`/images/bike-trip/gallery/lg/${item.filename}`} media="(min-width: 450px)" />
                <source srcSet={`/images/bike-trip/gallery/sm/${item.filename}, /images/bike-trip/gallery/lg/${item.filename} 2x`}
                        media="(max-width: 450px)" />
                <img className="photograph__image" srcSet={`/images/bike-trip/gallery/sm/${item.filename}`} alt={item.title} />
                <CaseNav direction="prev" ref={el => this.prevBtn = el} page={ prevExists ? data[curIndex - 1].url : 0 } isPhotograph="true" />
                <CaseNav direction="next" ref={el => this.nextBtn = el} page={ nextExists ? data[curIndex + 1].url : 0 } isPhotograph="true" />
              </picture>
            </div>
            <div className="photograph__controls">
              <Link className="photograph__btn photograph__btn--close" to="/bike-trip/pictures" />
              <span className="photograph__btn photograph__btn--info" onClick={this.toggleCaption} />
            </div>
            <div className="photograph__info-panel">
              <div className="photograph__txt">
                <h3 className="photograph__title">{item.title}</h3>
                <div dangerouslySetInnerHTML={createMarkup(item.caption)} />
              </div>
              <div className="photograph__country country">
      		      <span className={`country__flag country__${flag}`} />
      		      <h5 className="country__name">{country}</h5>
              </div>
            </div>
        </div>
      )
    }

    //  -------------------------------------- portfolio --------------------------------------
    else {
      // check if optional fields contain data. If so, prepare them for rendering
      let subtitle;
      let websiteExists;
      let roleExists;
      let skillsExist;

      if(item) {

        item.year === '0' ? subtitle = item.category : subtitle = item.category + ', ' + item.year;

        if(item.websiteURL) {
          websiteExists = <a className="exhibit__url" href={item.websiteURL}>{item.websiteURL}</a>
        }

        if(item.role) {
          roleExists = <p><span className="exhibit__label">Role:</span> { item.role }</p>
        }

        if(item.skills) {
          skillsExist = <p><span className="exhibit__label">Skills:</span> { item.skills }</p>
        }
      }

      // line up the next three pieces
      let seeMore = [];
      let curItem = curIndex;

      for(let i=0; i<3; i++) {
        curItem === (numPieces -1) ? curItem = 0: curItem ++;
        const item = data[curItem];

        seeMore.push(<Polaroid
          key={item.id}
          url={item.url}
          image={item.image}
          title={item.title}
          category={item.category} />);
      }

      return(
        <main className="main" data-bg="blusher-grad">
          <Helmet>
            <title>{`${item.title} - ${item.category}`}</title>
          </Helmet>
          <section className="exhibit">

            <div className="exhibit__visual">
        			<picture className="exhibit__picture">
                <source srcSet={`/images/lg/${item.image}`} media="(min-width: 1250px)" />
                <source srcSet={`/images/md/${item.image}, /images/lg/${item.image} 2x`} media="(min-width: 420px)" />
                <source srcSet={`/images/sm/${item.image}, /images/md/${item.image} 2x`} media="(max-width: 420px)" />
        				<img className="exhibit__image" srcSet={`/images/sm/${item.image}`} alt={item.title} width="440" height="248" />
        			</picture>
              <CaseNav ref={el => this.prevBtn = el} direction="prev" page={ prevExists ? data[curIndex - 1].url : 0 } />
              <CaseNav ref={el => this.nextBtn = el} direction="next" page={ nextExists ? data[curIndex + 1].url : 0 } />
            </div>

            <div className="wrapper exhibit__details" data-bg="white-grad">
              <Link to="/portfolio" className="exhibit__exit">
                Back <span className="exhibit__hide-mobile">to portfolio</span>
              </Link>
              <h1 className="exhibit__title">{item.title}</h1>
              <h2 className="exhibit__subtitle">{subtitle}</h2>
              { websiteExists }
              <div className="exhibit__txt" dangerouslySetInnerHTML={createMarkup(item.txt)} />

              <div className="exhibit__txt">
              { roleExists }
              { skillsExist }
              </div>
            </div>

          </section>

          <section className="wrapper polaroids" data-bg="blusher">
            <h3 className="polaroids__header">More work</h3>
            { seeMore }
          </section>
        </main>
      );
    }
  }
}

export default Case;

class CaseNav extends Component {
render(){
    const {
      direction,
      page,
      isPhotograph
    } = this.props;

    const classPrefix = isPhotograph ? 'exhibit' : 'photograph';

    if(page) {
     return <Link className={`${classPrefix}__nav ${classPrefix}__nav--${direction}`} to={page} />
   } else {
    return <span className={`${classPrefix}__nav ${classPrefix}__nav--inactive ${classPrefix}__nav--${direction}`} />
   }
  }
}
