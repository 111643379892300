import React, { Component } from 'react';
import classnames from 'classnames';
import sprite from "../styling/img/sprite.svg";
import { Svg, Use } from '@digital-detox/react-svg-use-external';

class Footer extends Component {
  render() {
    return(
      <footer className="footer">
        <ul className="footer__menu">
          <FooterItem icon="phone" url="tel:+31614558221">+31 (6) 1455 8221</FooterItem>
          <FooterItem icon="mail" url="mailto:info@barthendrix.com">info@barthendrix.com</FooterItem>
          <FooterItem isLinkedin isBlank icon="linkedin" url="https://www.linkedin.com/in/bart-hendrix-57bab241/"></FooterItem>
        </ul>
      </footer>
    );
  }
}

class FooterItem extends Component {
  render(){
    const {
      children,
      icon,
      url,
      isLinkedin,
      isBlank
    } = this.props;

    return (
      <li className={classnames({ 'footer__item': true, 'footer__item--linkedin' : isLinkedin })}>
        <a
          className="footer__link"
          target={isBlank && "_blank"}
          rel={isBlank && "noopener noreferrer"}
          href={url}>
          <Svg className="footer__icon">
            <Use xlinkHref={`${sprite}#icon-${icon}`} />
          </Svg>
          {children}
        </a>
      </li>
    )
  }
}

export default Footer;
