import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

class Nav extends Component {

  render() {

    const {
      closeMenu,
      toggleSubnav,
      subnavActive
    } = this.props;

    return(
      <ul className="nav">
      	<NavItem onClick={closeMenu} isExact to="home" />
        <NavItem onClick={closeMenu} to="portfolio" />
        <NavItem onClick={closeMenu} to="about" />
        <NavItem onClick={closeMenu} hasSubMenu="true" to="bike trip" toggleSubnav={toggleSubnav}>
          <ul className={classnames({
            'nav__subnav': true,
            'subnav': true,
            'subnav--active': subnavActive })}>
            <li><span className="subnav__toggler" onClick={toggleSubnav}>Back to main</span></li>
            <NavItem onClick={closeMenu} isExact to="/bike-trip" type="subnav" title="world map" />
            <NavItem onClick={closeMenu} to="/bike-trip/pictures" type="subnav" title="pictures" />
            <NavItem onClick={closeMenu} isExact to="/bike-trip/gear" type="subnav" title="gear" />
          </ul>
        </NavItem>
        <NavItem onClick={closeMenu} to="contact" />
      </ul>
    );
  }
}

class NavItem extends Component {
  render(){
    const {
      children,
      onClick,
      isExact,
      hasSubMenu,
      toggleSubnav,
      to,
      type,
      title
    } = this.props;

    // sanitize the link text and make it into a url
    let url = to.replace(' ', '-').replace(/^/,'/').replace('home', '');
    let subnavToggler = null;
    let classPrefix = 'nav';
    let txt;

    if(hasSubMenu) {
      subnavToggler = <span className="nav__subnav-toggler" onClick={toggleSubnav}></span>
    }

    title ? txt = title : txt = to;

    if(type === "subnav") {
      url = to;
      classPrefix = 'subnav';
    }

    return (
      <li className={`${classPrefix}__item`}>
        <NavLink
          className={`${classPrefix}__link`}
          exact={isExact}
          activeClassName={`${classPrefix}__link--here`}
          to={url}
          onClick={onClick}>
          {txt}
        </NavLink>
        {subnavToggler}
        {children}
      </li>
    )
  }
}

export default Nav;
