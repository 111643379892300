import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import classnames from 'classnames';
import delay from 'lodash/delay';
import PageNotReady from './PageNotReady';

import { createMarkup, getRoot } from '../utils';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutData: [],
      isLoading: true,
      error: null,
      showAnimation: false,
      animationDone: false
    };

    this.showBarChart = this.showBarChart.bind(this);
    this.source = axios.CancelToken.source();
  }

  componentDidMount() {
    window.scrollTo(0,0);

    const rootUrl = getRoot();

    this.axiosCancelSource = axios.CancelToken.source();
    axios.get(rootUrl + '/api/?request=about', { cancelToken: this.source.token })
      .then(result => {
        this.setState({
          aboutData: result.data[0],
          isLoading: false
        });
        // a classname needs to be removed to kick off the animation
        // build in a delay to make sure it is rendered with the classname that is going to be removed
        delay(this.showBarChart, 200);
      })
      .catch(error => this.setState({
        error,
        isLoading: false
      }));
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  showBarChart() {
    if(this.state.animationDone) {
      return;
    }
    this.setState({ showAnimation: true });
  }

  render() {

    const { aboutData, isLoading, error, showAnimation, animationDone } = this.state;

    if(isLoading) { return <PageNotReady pageType="loading" /> }
    if(error)     { return <PageNotReady pageType="error" errorMessage={error.message} /> }


    return (
      <main className="main wrapper">
        <Helmet>
          <title>About Bart Hendrix | Illustrator &amp; front-end developer</title>
          <meta name="description" content="I'm Bart Hendrix, illustrator and front-end developer based in Amsterdam." />
        </Helmet>

        <section className="pictorial" data-bg="pale-grad">
      		<div className="pictorial__txt">
      			<h1>About me</h1>
            <div dangerouslySetInnerHTML={ createMarkup(aboutData.intro) } />
      		</div>

          <div className="pictorial__visual">
      		  <div className="pictorial__img pictorial__img--pen"></div>
          </div>
      	</section>

        <section data-bg="blusher">
          <div className={classnames({
            'barchart': true,
            'barchart--done': animationDone,
            'barchart--active': showAnimation,
            'barchart--pristine': !showAnimation && !animationDone })}>
          <h2>Skills</h2>
            <dl className="barchart__container">
              <dt className="barchart__axis"><span className="barchart__central-axis"></span></dt>
              <dd className="barchart__bar" data-percent="92">
                <span className="barchart__txt">html</span>
              </dd>
              <dd className="barchart__bar" data-percent="98">
                <span className="barchart__txt">css</span>
              </dd>
              <dd className="barchart__bar" data-percent="73">
                <span className="barchart__txt">javascript</span>
              </dd>
              <dd className="barchart__bar" data-percent="41">
                <span className="barchart__txt">php</span>
              </dd>
              <dd className="barchart__bar" data-percent="90">
                <span className="barchart__txt">.psd</span>
              </dd>
              <dd className="barchart__bar" data-percent="85">
                <span className="barchart__txt">.ai</span>
              </dd>
              <dd className="barchart__bar" data-percent="19">
                <span className="barchart__txt">.aep</span>
              </dd>
            </dl>
        </div>
        </section>

        <section className="pictorial" data-bg="pale">

      		<div className="pictorial__txt">
            <h2>I'm for hire</h2>
            <p>
              If you have a project in mind and need an extra pair of hands or particular expertise, <Link to="contact">let me know</Link> - I’m currently available.
            </p>
      		</div>

      		<div className="pictorial__visual">
      			<div className="pictorial__img pictorial__img--paintbrushes"></div>
      		</div>

      	</section>
      </main>
    );
  }
}

export default About;
