import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import WorldMap from '../../components/WorldMap';
import Polaroid from '../../components/Polaroid';
import { getRandomArrayElements } from '../../utils';

class BikeTripLanding extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {

    const photoData = this.props.data;

    const slides = getRandomArrayElements(photoData, 3).map(photo => {
      return (
        <Polaroid
          key={photo.id}
          url={photo.url}
          image={photo.filename}
          title={photo.title}
          isPhotograph="true"
          isLazy="true"
        />
      )
    });

    return (
      <main className="main">
        <Helmet>
          <title>Bike trip</title>
        </Helmet>
        <WorldMap />
        <div className="wrapper">
          <div className="column column--narrow">
            <h1>Bike trip</h1>
            <p>
              In March 2018 I set off from my home in Amsterdam on a bike trip to the Great Wall of China. Fifteen months and more than thirty thousand kilometers later I returned, having not only made it to China but around the world.
            </p>
          </div>
          <section className="polaroids">
            <h3 className="polaroids__header">Pictures from the road</h3>
            { slides }
          </section>
          <p className="text-center">
            <Link to="/bike-trip/pictures" className="button">View all pictures</Link>
          </p>

          <div className="column column--narrow">
  					<h3>The route</h3>
  					<p>
              The first leg of my trip involved pedalling the length of the Eurasian landmass. Along the way I crossed the vast and awesome <a href="https://en.wikipedia.org/wiki/Karakalpakstan" target="_blank" rel="noopener noreferrer">Karakalpakstan steppe</a>, the <a href="https://caravanistan.com/tajikistan/pamir-highway/" target="_blank" rel="noopener noreferrer">Pamir highway</a>, and the <a href="https://en.wikipedia.org/wiki/Gobi_Desert" target="_blank" rel="noopener noreferrer">Gobi desert</a>.
            </p>

            <p>
              The second leg of the trip took me down under. Starting out from Perth, Australia, I inched my way across the seemingly infinite <a href="http://www.hemamaps.com/plan/location-guides/nullarbor-plain" target="_blank" rel="noopener noreferrer">Nullarbor plain</a>, had a brief taste of an <a href="https://www.theguardian.com/australia-news/2018/dec/24/christmas-day-heatwave-weather-records-temperatures-forecast-heat-wave-australia-sydney-melbourne-adelaide" target="_blank" rel="noopener noreferrer">Australian heatwave</a>, and finished off in wet and sandy fashion on New Zealand's <a href="https://viewsfromtheback.com/2019/02/03/week-8-in-new-zealand-we-made-it-to-the-top-and-clocked-5000-miles-cycled/" target="_blank" rel="noopener noreferrer">ninety mile beach</a>.
            </p>

            <p>
              Leg three saw me crossing the United States from coast to coast. Beginnning in San Francisco I followed the <a href="https://www.pacific-coast-highway-travel.com/Cycling-the-Pacific-Coast-Highway.html" target="_blank" rel="noopener noreferrer">Pacific Coast Highway</a> before turning east towards New York. En route I got to meet a cowboy, a Methodist pastor, and <a href="https://appalachiantrail.org" target="_blank" rel="noopener noreferrer">Appalachian hikers</a>.
            </p>

            <p>
              To complete the bike trip I set out from Porto, Portugal, followed the <a href="https://caminoways.com/ways" target="_blank" rel="noopener noreferrer">network of pilgrim routes</a> centered around Santiago de Compostela, and made my way back to Amsterdam from there.
            </p>
          </div>
          {/*  .column */}

          <div className="column column--narrow">
            <h3>How about skipping bits?</h3>
            <p>
              Not unless it was absolutely necessary. Riding a bicycle across oceans was not an option so that meant taking boats or aeroplanes. When on land I wanted to cover every millimetre by bicycle. These were the exceptions:
            </p>
            <ul>
              <li>
                5km through the <a href="https://en.wikipedia.org/wiki/Anzob_Tunnel" target="_blank" rel="noopener noreferrer">Anzob tunnel</a>, Tajikistan
              </li>
              <li>
                3km through the <a href="https://en.wikipedia.org/wiki/Töö_Ashuu" target="_blank" rel="noopener noreferrer">T&ouml;&ouml; Ashuu tunnel</a>, Kyrgyzstan
              </li>
              <li>
                40km of mandatory police escort in <a href="https://www.theguardian.com/news/2018/aug/07/why-uighur-muslims-across-china-are-living-in-fear" target="_blank" rel="noopener noreferrer">Xinjiang province</a>, China
              </li>
            </ul>
          </div>
          {/*  .column */}

          <div className="column column--narrow">
            <h3>Gear</h3>
            <p>
              For curious bike and camping gear nerds, <Link to="/bike-trip/gear">here's</Link> a detailed list of the stuff I took along with me.
            </p>
            <p>
              <Link to="/bike-trip/gear" className="button">View kit list</Link>
            </p>
          </div>
          {/*  .column */}

          <span className="spacer"></span>
        </div>
      </main>
    );
  }
}

export default BikeTripLanding;
