import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Polaroid from '../components/Polaroid';

import { getRandomArrayElements } from '../utils';

class PageNotFound extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {

    const imageData = this.props.data;
    const isPhotograph = this.props.isPhotograph;
    let slides = [];

    if(isPhotograph) {
      slides = getRandomArrayElements(imageData, 3).map(slide => {

        return (
          <Polaroid
            key={slide.id}
            url={slide.url}
            image={slide.filename}
            title={slide.title}
            isPhotograph
          />
        )
      });
    }
    else {
      slides = getRandomArrayElements(imageData, 3).map(slide => {

        return (
          <Polaroid
            key={slide.id}
            url={slide.url}
            image={slide.image}
            title={slide.title}
            category={slide.category} />
        )
      });
    }

    return (
      <main className="main wrapper" data-bg="blusher-grad">
        <Helmet>
          <title>404 - page not found</title>
        </Helmet>
      	<div className="page-not-found" data-bg="white-grad">
      		<h1>Page not found</h1>
      		<div className="page-not-found__visual"></div>
      		<p>Sorry about that - 404 error.</p>
      		<p><Link className="button" to="/">Ok, get me out of here</Link></p>
      	</div>
        <section className="polaroids" data-bg="blusher">
          <h3 className="polaroids__header">
          {
            isPhotograph ? 'Pictures from the road' : 'Random work'
          }
          </h3>
          { slides }
        </section>
      </main>
    );
  }
}

export default PageNotFound;
