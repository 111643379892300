import React, { Component } from 'react';
import { Link }   from 'react-router-dom';

import classnames from 'classnames';
import throttle   from 'lodash/throttle';
import debounce   from 'lodash/debounce';
import delay      from 'lodash/delay';

import Nav from './Nav';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sticky: false,
      resizing: false,
      navActive: false,
      subnavActive: false,
    }

    this.handleScroll = throttle(this.handleScroll, 200);
    this.duringResize = throttle(this.duringResize, 200);
    this.afterResize = debounce(this.afterResize, 500);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleSubnav = this.toggleSubnav.bind(this);

  }

  componentDidMount() {
    // listeners for the window scroll and resize events.
    // This is needed for the sticky header and to prevent stuff from animating when resizing the viewport
    window.addEventListener('scroll', this.handleScroll, false);
    window.addEventListener('resize', this.duringResize, false);
    window.addEventListener('resize', this.afterResize, false);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.duringResize);
    window.removeEventListener('resize', this.afterResize);
  }

  handleScroll = () => {
    const flag = 20;
    window.scrollY >= flag ? this.setState({ sticky: true }) : this.setState({ sticky: false });
  }

  duringResize = () =>  { this.setState({ resizing: true }); }
  afterResize = () =>   { this.setState({ resizing: false }); }

  // toggle the state of the mobile navigation menu. It's a simple true / false flag
  toggleNav() {
    const isActive = this.state.navActive;
		this.setState({ navActive: !isActive });

    if(isActive) {
      delay(() => {
        this.setState({ subnavActive: false });
      }, 200);
    }
  }

  toggleSubnav() {
    const isActive = this.state.subnavActive;
		this.setState({ subnavActive: !isActive });
  }

  closeMenu = (flag) => {
    this.setState({ navActive: false });
  }

  render() {

    const { sticky, resizing, navActive, subnavActive } = this.state;

    return (
      <header className={classnames({ 'header': true, 'header--sticky': sticky, 'header--resizing': resizing, 'header--navigating': navActive })}>
        <Link className="branding" to="/">
          <span className="branding__visual">
            <span className="branding__pixel" />
            <span className="branding__pixel" />
            <span className="branding__pixel" />
            <span className="branding__pixel" />
          </span>
          Pixel polisher
        </Link>
        <span className={classnames({ 'nav-toggler': true, 'nav-toggler--active': navActive })} onClick={this.toggleNav}>
          <span className="nav-toggler__divider"></span>
        </span>
        <Nav
          closeMenu={this.closeMenu}
          toggleSubnav={this.toggleSubnav}
          subnavActive={subnavActive} />
      </header>
    )
  }
}

export default Header;
