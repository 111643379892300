import './styling/css/pixelpolisher.css';

// core dependencies
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { getRoot } from './utils';

// components
import Header       from './components/Header';
import Footer       from './components/Footer';
import Gallery      from './components/Gallery';
import Case         from './components/Case';

// pages
import Home               from './pages/Home';
import About              from './pages/About';
import BikeTripLanding    from './pages/bike-trip/Landing';
import BikeTripGear       from './pages/bike-trip/Gear';
import Contact            from './pages/Contact';
import PageNotFound       from './pages/PageNotFound';
import PageNotReady       from './pages/PageNotReady';

class App extends Component {
  constructor() {
    super();

    this.state = {
      portfolioData: [],
      photographData: [],
      isLoading: true,
      error: null,
      scrollItem: null
    };
  }

  componentDidMount() {

    const rootUrl = getRoot();

    axios.all([
      axios.get(rootUrl + '/api/?request=portfolio'),
      axios.get(rootUrl + '/api/?request=photographs')
    ])
    .then(axios.spread((portfolio, photographs) => {
      this.setState({
        portfolioData: portfolio.data,
        photographData: photographs.data,
        isLoading: false
      })
    }))
    .catch(error => this.setState({
      error,
      isLoading: false
    }));
  }

  updateScrollItem = (url) => {
    this.setState({ scrollItem: url });
  }

  render() {

    const { portfolioData, photographData, isLoading, error, scrollItem } = this.state;

    if(isLoading) { return <div className="app"><PageNotReady pageType="loading" /></div> }
    if(error)     { return <div className="app"><PageNotReady pageType="error" errorMessage={error.message} /></div> }

    return (
      <div className="app">
        <Header  />
        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} data={portfolioData} />} />
          <Route exact path="/portfolio"
                 render={(props) => <Gallery {...props}
                 data={portfolioData}
                 scrollToItem={scrollItem}
                 updateScrollItem={this.updateScrollItem} />} />
          <Route exact path="/portfolio/:name"
                 render={(props) => <Case {...props}
                 data={portfolioData}
                 updateScrollItem={this.updateScrollItem} />} />
          <Route path="/about" component={About} />
          <Route exact path="/bike-trip" render={(props) => <BikeTripLanding {...props} data={photographData} />} />
          <Route exact path="/bike-trip/pictures"
                 render={(props) => <Gallery {...props}
                 data={photographData}
                 isPhotograph="true"
                 scrollToItem={scrollItem}
                 updateScrollItem={this.updateScrollItem} />} />
          <Route exact path="/bike-trip/pictures/:name"
                 render={(props) => <Case {...props} data={photographData}
                 isPhotograph="true"
                 updateScrollItem={this.updateScrollItem} />} />
          <Route exact path="/bike-trip/gear" component={BikeTripGear} />
          <Route exact path="/contact" component={Contact} />
          <Route path="*" render={(props) => <PageNotFound {...props} data={portfolioData} />} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
