import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import PageNotReady from '../PageNotReady';
import Accordion from '../../components/Accordion';

import { getRoot } from '../../utils';

class BikeTripGear extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gearData: [],
      isLoading: true,
      error: null
    };

    this.source = axios.CancelToken.source();
  }

  componentDidMount() {

    window.scrollTo(0,0);

    const rootUrl = getRoot();

    axios.get(rootUrl + '/api/?request=gear', { cancelToken: this.source.token })
    .then(result => {
      this.setState({
        gearData: result.data,
        isLoading: false
      });
    })
    .catch(error => this.setState({
      error,
      isLoading: false
    }));
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  render() {

    const { gearData, isLoading, error } = this.state;

    if(isLoading) { return <PageNotReady pageType="loading" /> }
    if(error)     { return <PageNotReady pageType="error" errorMessage={error.message} /> }

    return(
      <main className="main" data-bg="blusher-grad">
        <Helmet>
          <title>Bike Trip | Gear</title>
        </Helmet>
        <div className="wrapper">
          <div className="column">
            <h1 className="fancy-header">Bike trip | Gear</h1>
            <Accordion data={gearData} />
          </div>
        </div>
      </main>
    )
  }
}

export default BikeTripGear;
