import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Polaroid from './Polaroid';

class Gallery extends Component {

  constructor() {
    super();

    this.resetScrollItem = this.resetScrollItem.bind(this);
  }

  resetScrollItem(){
    this.props.updateScrollItem(null);
  }

  render() {

    let scrollItem = null;

    if(this.props.scrollToItem) {
      const item = this.props.data.find(item => item.url === this.props.scrollToItem);
      if(item) { scrollItem = item.url; }
    }

    const data = this.props.data;
    const isPhotograph = this.props.isPhotograph;

    if(isPhotograph) {
      return <BikeTripGallery data={data} scrollItem={scrollItem} resetScrollItem={this.resetScrollItem} />
    }
    else {
      return <Portfolio data={data} scrollItem={scrollItem} resetScrollItem={this.resetScrollItem} />
    }
  }
}

export default Gallery;

class Portfolio extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.resetScrollItem();
  }

  render() {
    const { data, scrollItem } = this.props;
    const polaroids = data.map((item) => {

      const scrollOn = item.url === scrollItem;
      return (
        <Polaroid
          key={item.id}
          url={item.url}
          image={item.image}
          title={item.title}
          category={item.category}
          scrollOn={scrollOn}
          isLazy="true" />
      )
    });
    return (
      <main className="main wrapper" data-bg="blusher-grad">
        <Helmet>
          <title>Portfolio | pixel polisher</title>
        </Helmet>
        <h1 className="fancy-header">Portfolio</h1>
        <div className="polaroids">
          { polaroids }
        </div>
      </main>
    )
  }
}

class BikeTripGallery extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.resetScrollItem();
  }

  render() {
    const { data, scrollItem } = this.props;
    const polaroids = data.map((item) => {

      const scrollOn = item.url === scrollItem;
      return (
        <Polaroid
          key={item.id}
          url={item.url}
          image={item.filename}
          title={item.title}
          scrollOn={scrollOn}
          isPhotograph="true"
          isLazy="true" />

      )
    });
    return (
      <main className="main wrapper" data-bg="blusher-grad">
        <Helmet>
          <title>Bike trip | Pictures</title>
        </Helmet>
        <h1 className="fancy-header">Bike Trip | Pictures</h1>
        <div className="polaroids">
          { polaroids }
        </div>
      </main>
    )
  }
}
