import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import classnames from 'classnames';
import delay from 'lodash/delay';

class Polaroid extends Component {

  render() {
    const {
      url,
      image,
      title,
      category,
      scrollOn,
      isPhotograph,
      isLazy
    } = this.props;

    if(scrollOn === true && this.polaroidRef) {
      // the 0 second delay forces ie11 to play nice
      delay(() => {
        const polaroidTop = Math.round( this.polaroidRef.getBoundingClientRect().top - 60);
        window.scroll(0, polaroidTop);
      }, 0);
    }

    if(isPhotograph) {
      return (
        <Link
          to={`/bike-trip/pictures/${url}` }
          className={classnames({ 'polaroid': true, 'polaroid--photograph': true, 'polaroid--active': scrollOn })}>
          <span ref={(el) => this.polaroidRef = el } />
          <PolaroidFigure isLazy={isLazy}>
            <img className="polaroid__image" src={`/images/bike-trip/gallery/sm/${image}`} alt={title} />
          </PolaroidFigure>
          <h5 className="polaroid__header">{title}</h5>
        </Link>
      )
    }
    else {
      return(
        <Link
          to={`/portfolio/${url}` }
          className={classnames({ 'polaroid': true, 'polaroid--active': scrollOn })}>
          <span ref={(el) => this.polaroidRef = el } />
          <PolaroidFigure isLazy={isLazy}>
            <img className="polaroid__image" src={`/images/sm/${image}`} alt={title} />
          </PolaroidFigure>
          <h4 className="polaroid__header">{title}</h4>
          <p className="polaroid__subheader">{category}</p>
        </Link>
      )
    }
  }
}

export default Polaroid;

class PolaroidFigure extends Component {
  render() {
    const { isLazy, children } = this.props;

    if(isLazy) {
      return (
        <figure className="polaroid__figure">
          <LazyLoad>{children}</LazyLoad>
        </figure>
      )
    }
    else {
      return <figure className="polaroid__figure">{children}</figure>
    }
  }
}
